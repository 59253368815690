import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';
import {setToken} from "../axios";
import { setLoginData, setKYC, setGKyc, toggleStatusModal, setLoader, setKYB, setGKyb, setStaking, setDAO } from '../actions/Auth';

/*========== LOGIN FUNCTIONS =============*/

function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: '/v1/loginAdmin', payload });
  if (error) {
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    const decoded = jwt_decode(response["data"]["body"]["token"]);
    if (decoded["role"] !== "admin") {
      EventBus.publish("error", "Can't login through User account ");
      return;
    }
    setToken(response["data"]["body"]["token"]);
    yield put(setLoginData(response["data"]["body"]["token"]));
    yield put({ type: "TOGGLE_LOGIN" });
    EventBus.publish("success", response['data']['message'])
    // setTimeout(() => history.push('/home'), 1000);
  }
};


/*========== KYC FUNCTIONS =============*/

function* getKYC({payload}) {
  const { error, response } = yield call(getCall, '/v1/kycApproved/'+payload['chainId']);
  if (error){ 
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setLoader({status:false}));
    yield put(setKYC(response['data']['body']));
  }
};

function* kycGlobal({payload}) {
  const { error, response } = yield call(getCall, '/v1/kycGlobal/'+payload['chainId']);
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setGKyc(response['data']['body']));
    yield put(setLoader({status:false}));
  }
};

export function* updateKYC({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v1/kycUpdate",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYC" });
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* setApprovedUser({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v1/setApprovedUser",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({status:false}));
    yield put(setGKyc(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* deleteUser({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v1/deleteUser",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({status:false}));
    yield put(setGKyc(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}


/*========== KYB FUNCTIONS =============*/

function* getKYB({payload}) {
  const { error, response } = yield call(getCall, '/v1/getPendingKyb/'+payload['chainId']);
  if (error){ 
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setLoader({status:false}));
    yield put(setKYB(response['data']['body']));
  }
};

function* kybGlobal({payload}) {
  const { error, response } = yield call(getCall, '/v1/kybGlobal/'+payload['chainId']);
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(setGKyb(response['data']['body']));
    yield put(setLoader({status:false}));
  }
};

export function* updateKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v1/kybUpdate",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish('error', error['response']['data']['message']);
  } else if (response) {
    yield put(toggleStatusModal(false));
    yield put({ type: "GET_KYB" });
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* setApprovedKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v1/setApprovedKYB",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({status:false}));
    yield put(setGKyb(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}

export function* deleteKYB({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/v1/deleteKYB",
    payload,
  });
  if (error) {
    yield put(setLoader({status:false}));
    EventBus.publish("error", error["response"]["data"]["message"]);
  } else if (response) {
    yield put(setLoader({status:false}));
    yield put(setGKyb(response['data']['body']));
    EventBus.publish("success", response["data"]["message"]);
  }
}

function* uploadMetadata({ payload }) {
  console.log("******* payload",payload);
  const { error, response } = yield call(postCall, { path: '/v1/uploadMetadataKyb', payload });
  if (error) {
    yield put(setLoader({ status: false }));
    EventBus.publish("error", error['response']['data']['message']);
  }
  else if (response) {
    yield put(setLoader({ status: false }));
  }
};


/*========== STATS FUNCTIONS =============*/

export function* getStaking() {
  const { error, response } = yield call(getCall, "/v3/getStaking");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setStaking(response['data']['body']));
  }
};

export function* getDAO() {
  const { error, response } = yield call(getCall, "/v2/getDAO");
  if (error) { EventBus.publish("error", error['response']['data']['message']); }
  else if (response) {
    yield put(setDAO(response['data']['body']));
  }
};

function* actionWatcher() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('GET_KYC', getKYC);
  yield takeEvery('KYC_GLOBAL', kycGlobal);
  yield takeEvery('UPDATE_KYC', updateKYC);
  yield takeEvery('SET_APPROVED_USER', setApprovedUser);
  yield takeEvery('DELETE_USER', deleteUser);

  yield takeEvery('GET_KYB', getKYB);
  yield takeEvery('KYB_GLOBAL', kybGlobal);
  yield takeEvery('UPDATE_KYB', updateKYB);
  yield takeEvery('SET_APPROVED_KYB', setApprovedKYB);
  yield takeEvery('DELETE_KYB', deleteKYB);
  yield takeEvery('UPLOAD_METADATA', uploadMetadata);

  yield takeEvery('GET_STAKING', getStaking)
  yield takeEvery('GET_DAO', getDAO)
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
