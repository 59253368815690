/*========== LOGIN ACTIONS ============= */

export const login = (data) => ({
      type: 'LOGIN',
      payload: data,
    });

export const setLoginData = (data) => ({
  type: 'SET_LOGIN_DATA',
  payload: data,
});

export const toggleLogin = () => ({
  type: 'TOGGLE_LOGIN',
});

export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

/*========== PAGE LOADER ACTIONS ============= */

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});


/*========== KYC ACTIONS ============= */

export const getKYC = (data) => ({
  type: 'GET_KYC',
  payload: data
});

export const setKYC = (data) => ({
  type: 'SET_KYC',
  payload: data
});

export const setApprovedUser = (data) => ({
  type: 'SET_APPROVED_USER',
  payload: data
});

export const deleteUser = (data) => ({
  type: 'DELETE_USER',
  payload: data
});


export const updateKYC = (data) => ({
  type: 'UPDATE_KYC',
  payload: data
});

export const toggleStatusModal = (data) => ({
  type: 'TOGGLE_STATUS_MODAL',
  payload: data
});

export const kycGlobal = (data) => ({
  type: 'KYC_GLOBAL',
  payload: data
});

export const setGKyc = (data) => ({
  type: 'SET_G_KYC',
  payload: data
});

/*========== KYB ACTIONS ============= */

export const getKYB = (data) => ({
  type: 'GET_KYB',
  payload: data
});

export const setKYB = (data) => ({
  type: 'SET_KYB',
  payload: data
});

export const setApprovedKYB = (data) => ({
  type: 'SET_APPROVED_KYB',
  payload: data
});

export const deleteKYB = (data) => ({
  type: 'DELETE_KYB',
  payload: data
});

export const updateKYB = (data) => ({
  type: 'UPDATE_KYB',
  payload: data
});

export const kybGlobal = (data) => ({
  type: 'KYB_GLOBAL',
  payload: data
});

export const setGKyb = (data) => ({
  type: 'SET_G_KYB',
  payload: data
});

export const uploadMetadata = (data) => ({
  type: 'UPLOAD_METADATA',
  payload: data
});

/*========== STATS ACTIONS ============= */

export const getStaking = () => ({
  type: 'GET_STAKING'
});

export const setStaking = (data) => ({
  type: 'SET_STAKING',
  payload: data,
});

export const getDAO = () => ({
  type: 'GET_DAO'
});

export const setDAO = (data) => ({
  type: 'SET_DAO',
  payload: data,
});