import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from "react";
import * as ethUtil from "ethereumjs-util";
import Button from "@material-ui/core/Button";
import { ValidatorForm } from "react-material-ui-form-validator";

import { web3 } from "../../store/web3";
import logo from '../../assets/img/logoWhite.svg';
import XDC from '../../assets/img/xdc.svg';
import { login } from "../../store/actions/Auth";
import { setProviderAddress} from "../../store/config";
import { Token, Approve} from "../../store/contract/index";


import "./index.css";
import { Fragment } from "react";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      netId: "",
      address: "",
      message: ""
    };
  }
  
  // PRODUCTION: XDC MAINNET
  // async componentDidMount() {
  //   let chain = await web3.eth.getChainId();
  //   chain = web3.utils.hexToNumber(chain);
  //   chain = Number(chain);
  //   this.setState({ netId: chain });
  //   if (!window.ethereum) {
  //     EventBus.publish("error", "Please install Metamask");
  //     return;
  //   }

  //   // Define network details
  //   let rpcUrl, chainId, chainName, nativeCurrency, blockExplorerUrl;
  //   switch (networkId) {
  //     case 50:
  //       rpcUrl = 'https://erpc.xinfin.network';
  //       chainId = '0x32';
  //       chainName = 'XDC Mainnet';
  //       nativeCurrency = {
  //         name: 'XDC',
  //         symbol: 'XDC',
  //         decimals: 18,
  //       };
  //       blockExplorerUrl = 'https://xdc.blocksscan.io/';
  //       break;
  //     default:
  //       return Promise.reject(new Error('Invalid network'));
  //   }
  //   const netId = await web3.eth.net.getId();
  //   if (netId.toString() !== chainId.toString()) {
  //     await window.ethereum.request({
  //       method: 'wallet_addEthereumChain',
  //       params: [
  //         {
  //           chainId: chainId,
  //           chainName: chainName,
  //           nativeCurrency: nativeCurrency,
  //           rpcUrls: [rpcUrl],
  //           blockExplorerUrls: [blockExplorerUrl],
  //         },
  //       ],
  //     });
  //   }
  //   else {
  //     // Switch to the selected network
  //     await web3.eth.switchNetwork(chainId);
  //   }
  //   this.checkAddresses();
  // }

  // DEVELOPMENT: XDC TESTNET
  async componentDidMount() {
    let {
      networkId
    } = await setProviderAddress(web3);
    if(networkId){
    this.setState({networkId})
    let chain = await web3.eth.getChainId();
    chain = web3.utils.hexToNumber(chain);
    chain = Number(chain);
    this.setState({ netId: chain });
    if (!window.ethereum) {
      EventBus.publish("error", "Please install Metamask");
      return;
    }
    
    // Define network details
    let rpcUrl, chainId, chainName, nativeCurrency, blockExplorerUrl;
    switch (networkId) {
      case 51:
        rpcUrl = ['https://earpc.apothem.network','https://erpc.apothem.network'];
        chainId = '0x33';
        chainName = 'XDC Testnet';
        nativeCurrency = {
          name: 'XDC',
          symbol: 'XDC',
          decimals: 18,
        };
        blockExplorerUrl = 'https://explorer.apothem.network';
        break;
      case 80002:
        rpcUrl = ['https://rpc-amoy.polygon.technology','https://polygon-amoy-bor-rpc.publicnode.com'];
        chainId = '0x13882';
        chainName = 'Amoy';
        nativeCurrency = {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        };
        blockExplorerUrl = 'https://www.oklink.com/amoy';
        break;
      default:
        return Promise.reject(new Error('Invalid network'));
    }
    const netId = await web3.eth.net.getId();
    if (netId.toString() !== chainId.toString()) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: chainId,
            chainName: chainName,
            nativeCurrency: nativeCurrency,
            rpcUrls: rpcUrl,
            // blockExplorerUrls: [blockExplorerUrl],
          },
        ],
      });
    }
    else {
      // Switch to the selected network
      await web3.eth.switchNetwork(chainId);
    }
    this.checkAddresses();
    }
  }

  checkAddresses = async () => {
    let address = (await web3.currentProvider.enable())[0];
    this.setState({ address });
  };

  handleLogin = async () => {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install Metamask");
      return;
    }
    let address = (await web3.currentProvider.enable())[0];
    if (!address) return EventBus.publish("error", "Wallet Address Not Found");
    const nonce = Math.floor(Math.random() * 10000000000);
    const signature = await web3.eth.personal.sign(
      web3.utils.utf8ToHex(`CIFI Admin ${nonce}`),
      address
    );

    const msg = new Buffer(`CIFI Admin ${nonce}`);
    const msgBuffer = ethUtil.toBuffer(msg);
    const msgHash = ethUtil.hashPersonalMessage(msgBuffer);
    const signatureBuffer = ethUtil.toBuffer(signature);
    const signatureParams = ethUtil.fromRpcSig(signatureBuffer);
    const publicKey = ethUtil.ecrecover(
      msgHash,
      signatureParams.v,
      signatureParams.r,
      signatureParams.s
    );
    const addressBuffer = ethUtil.publicToAddress(publicKey);
    const adminAddress = ethUtil.bufferToHex(addressBuffer);

    if (address) {
      // web3.eth.net.getId((err, chainId)=>
        this.props.login({ publicAddress: address })
      // )
      setTimeout(() => window.location.reload(), 2000);
      // setTimeout(() => this.props.history.push('/home'), 1000);
    }
    else EventBus.publish("error", "Please login through super admin address");
  };

  render() {
    let { netId, networkId, message} = this.state;
    if (this.props.isLogin) return this.props.history.push('/home');
    return (
      <div className="login-page">
        <div className="login-area mx-auto">
          <div className="login-form">
            <h4 className="login-title">
              {/* <img src={logo} alt="logo" className="logoLogin" /> */}
              <img src="images/logo.svg" alt="logo" className="logoLogin" />
              {/* Global KYC */}
            </h4>
            <h1>Welcome CiFI Admin</h1>
            <p className="text-white">
              Access to manage the CiFi kyc dApp
            </p>
            {netId != networkId ?
               netId != networkId ?
               (
                 <div className="login-text">
                   <p>Please connect XDC or Polygon</p>
                 </div>
               )
               :(
                <Fragment>
                  <ValidatorForm
                    className="validator-form"
                    onSubmit={this.handleLogin}
                  >
                    <Button
                      type="Submit"
                      variant="contained"
                      className="text-white login-btn"
                    >
                      Login with Wallet
                      {/* <img src={XDC} alt="xdc" className='xdc' /> */}
                    </Button>
                  </ValidatorForm>
                </Fragment>
              )
             :(
              <Fragment>
                <ValidatorForm
                  className="validator-form"
                  onSubmit={this.handleLogin}
                >
                  <Button
                    type="Submit"
                    variant="contained"
                    className="text-white login-btn"
                  >
                    Login with Wallet
                    {/* <img src={XDC} alt="xdc" className='xdc' /> */}
                  </Button>
                </ValidatorForm>
              </Fragment>
            )}
          </div>
        </div>
        <div className='bgLoginPg'></div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  login,
};

const mapStateToProps = ({ Auth }) => {
  let { isLogin } = Auth;
  return { isLogin };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
