import React from "react";
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';

import './index.css';
import { logout, setAddress } from '../../store/actions/Auth';

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      role: localStorage.getItem('role'),
    };
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };

  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };

  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  logout = () => {
    this.props.logout();
    this.props.history.push('/');
  };

  copied = () => EventBus.publish("success", 'Address is Copied');

  render() {
    return (
      <div className="nav-bar">

      </div>
    );
  }
}

const mapDispatchToProps = { logout, setAddress };

const mapStateToProps = ({ Auth }) => {
  let { role, balance, address } = Auth;
  return { role, balance, address };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);