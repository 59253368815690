/* -- set app title --*/
const AppTitle = 'CiFI Admin KYC';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['development'];

/* -- set API URLs --*/
const testing = 'https://www.servergroot.grootdex.io';
const production = 'https://server.cifiapp.com';
// const production = 'https://server.cifiapp.com';
// const development = 'http://localhost:4000';
const development = 'https://www.servergroot.grootdex.io';

const GreenCrossWhitelistData = require(`./contract/${AppMode[0]}XDC/GreenCrossWhitelist.json`);
const CIFIGIVEWhitelistData = require(`./contract/${AppMode[0]}XDC/CIFIGIVEWhitelist.json`);
const DAOMultiSignatureData = require(`./contract/${AppMode[0]}XDC/DAOMultiSignature.json`);
const AdminWhitelistData = require(`./contract/${AppMode[0]}XDC/AdminWhitelist.json`);
const SoulBoundTokenData = require(`./contract/${AppMode[0]}XDC/SoulBondToken.json`);
const MetaDataURIContract = require(`./contract/${AppMode[0]}XDC/MetaDataURI.json`);
const SubscriptionData = require(`./contract/${AppMode[0]}XDC/Subscription.json`);
const CIFINFTStakeData = require(`./contract/${AppMode[0]}XDC/CIFINFTStake.json`);
const VIPNFTData = require(`./contract/${AppMode[0]}XDC/VIPNFT.json`);
const GOVNFTData = require(`./contract/${AppMode[0]}XDC/GOVNFT.json`);
const TokenData = require(`./contract/${AppMode[0]}XDC/Token.json`);
const SPVData = require(`./contract/${AppMode[0]}XDC/SPVNFT.json`);
const SP1Data = require(`./contract/${AppMode[0]}XDC/SP1.json`);
const SP2Data = require(`./contract/${AppMode[0]}XDC/SP2.json`);

const GreenCrossWhitelistDataMATIC = require(`./contract/${AppMode[0]}MATIC/GreenCrossWhitelist.json`);
const CIFIGIVEWhitelistDataMATIC = require(`./contract/${AppMode[0]}MATIC/CIFIGIVEWhitelist.json`);
const AdminWhitelistDataMATIC = require(`./contract/${AppMode[0]}MATIC/AdminWhitelist.json`);
const SoulBoundTokenDataMATIC = require(`./contract/${AppMode[0]}MATIC/SoulBondToken.json`);
const MetaDataURIContractMATIC = require(`./contract/${AppMode[0]}MATIC/MetaDataURI.json`);
const SubscriptionDataMATIC = require(`./contract/${AppMode[0]}MATIC/Subscription.json`);
const CIFINFTStakeDataMATIC = require(`./contract/${AppMode[0]}MATIC/CIFINFTStake.json`);
const VIPNFTDataMATIC = require(`./contract/${AppMode[0]}MATIC/VIPNFT.json`);
const GOVNFTDataMATIC = require(`./contract/${AppMode[0]}MATIC/GOVNFT.json`);
const TokenDataMATIC = require(`./contract/${AppMode[0]}MATIC/Token.json`);
const SPVDataMATIC = require(`./contract/${AppMode[0]}MATIC/SPVNFT.json`);
const SP1DataMATIC = require(`./contract/${AppMode[0]}MATIC/SP1.json`);
const SP2DataMATIC = require(`./contract/${AppMode[0]}MATIC/SP2.json`);

let SocketUrl;
let env = AppMode[0] || 'development';

const setProviderAddress = (web3) => {
  return new Promise((resolve, reject) => {
    if (web3) {
      if (AppMode[0] === 'development') {
        return web3.eth.net.getId(async (err, netId) => {
          let networkId = '', message = '', explorer = '', networkIdPolygon = '',
            SoulBondTokenAbi = '', SoulBondTokenBytecode = '', CIFIGIVEWhitelistAddress = '',
            CIFIGIVEWhitelistABI = '', CIFIGIVEWhitelist = '', GreenCrossWhitelistAddress = '', GreenCrossWhitelistABI = '', GreenCrossWhitelist = '',
            AdminWhitelistAddress = '', AdminWhitelistABI = '', AdminWhitelist = '', MetaDataURIAddress = '', MetaDataURIABI = '', MetaDataURI = '',
            SubscriptionAddress = '', SubscriptionABI = '', Subscription = '', SPVNFTAddress = '', SPVNFTABI = '', SPVNFT = '',
            VIPNFTAddress = '', VIPNFTABI = '', VIPNFT = '', GOVNFTAddress = '', GOVNFTABI = '', GOVNFT = '', VIPNFTStakeAddress = '', GOVNFTStakeAddress = '',
            CIFITokenAddress = '', CIFITokenABI = '', CIFIToken = '', REFITokenAddress = '', REFITokenABI = '', REFIToken = '',
            NFTStakeABI = '', VIPNFTStake = '', GOVNFTStake = '', DAOMultiSignatureABI = '', SP1NFTStakeAddress = '', SP1NFTStakeABI = '', SP1NFTStake = '',
            SP2NFTStakeAddress = '', SP2NFTStakeABI = '', SP2NFTStake = '';
          if (netId == 51) {
            networkId = 51;
            SocketUrl = development;
            message = 'Please switch your network to XDC Testnet';
            explorer = 'https://explorer.apothem.network'

            SoulBondTokenAbi = SoulBoundTokenData['abi'];
            SoulBondTokenBytecode = SoulBoundTokenData['bytecode'];

            CIFIGIVEWhitelistAddress = CIFIGIVEWhitelistData['address']
            CIFIGIVEWhitelistABI = CIFIGIVEWhitelistData['abi'];
            CIFIGIVEWhitelist = new web3.eth.Contract(CIFIGIVEWhitelistABI, CIFIGIVEWhitelistAddress);
            GreenCrossWhitelistAddress = GreenCrossWhitelistData['address']
            GreenCrossWhitelistABI = GreenCrossWhitelistData['abi'];
            GreenCrossWhitelist = new web3.eth.Contract(GreenCrossWhitelistABI, GreenCrossWhitelistAddress);

            AdminWhitelistAddress = AdminWhitelistData['address']
            AdminWhitelistABI = AdminWhitelistData['abi'];
            AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

            MetaDataURIAddress = MetaDataURIContract['address']
            MetaDataURIABI = MetaDataURIContract['abi'];
            MetaDataURI = new web3.eth.Contract(MetaDataURIABI, MetaDataURIAddress);

            SubscriptionAddress = SubscriptionData['address']
            SubscriptionABI = SubscriptionData['abi'];
            Subscription = new web3.eth.Contract(SubscriptionABI, SubscriptionAddress);

            SPVNFTAddress = SPVData['address']
            SPVNFTABI = SPVData['abi'];
            SPVNFT = new web3.eth.Contract(SPVNFTABI, SPVNFTAddress);

            VIPNFTAddress = VIPNFTData['address']
            VIPNFTABI = VIPNFTData['abi'];
            VIPNFT = new web3.eth.Contract(VIPNFTABI, VIPNFTAddress);

            GOVNFTAddress = GOVNFTData['address']
            GOVNFTABI = GOVNFTData['abi'];
            GOVNFT = new web3.eth.Contract(GOVNFTABI, GOVNFTAddress);

            VIPNFTStakeAddress = CIFINFTStakeData['vipAddress']
            NFTStakeABI = CIFINFTStakeData['abi'];
            VIPNFTStake = new web3.eth.Contract(NFTStakeABI, VIPNFTStakeAddress);

            GOVNFTStakeAddress = CIFINFTStakeData['govAddress']
            NFTStakeABI = CIFINFTStakeData['abi'];
            GOVNFTStake = new web3.eth.Contract(NFTStakeABI, GOVNFTStakeAddress);

            SP1NFTStakeAddress = SP1Data['address']
            SP1NFTStakeABI = SP1Data['abi'];
            SP1NFTStake = new web3.eth.Contract(SP1NFTStakeABI, SP1NFTStakeAddress);

            SP2NFTStakeAddress = SP2Data['address']
            SP2NFTStakeABI = SP2Data['abi'];
            SP2NFTStake = new web3.eth.Contract(SP2NFTStakeABI, SP2NFTStakeAddress);

            CIFITokenAddress = TokenData['cifiAddress']
            CIFITokenABI = TokenData['abi'];
            CIFIToken = new web3.eth.Contract(CIFITokenABI, CIFITokenAddress);

            REFITokenAddress = TokenData['refiAddress']
            REFITokenABI = TokenData['abi'];
            REFIToken = new web3.eth.Contract(REFITokenABI, REFITokenAddress);

            DAOMultiSignatureABI = DAOMultiSignatureData['abi'];
          }
          else if (netId == 80002) {

            networkId = 80002;
            SocketUrl = development;
            message = 'Please switch your network to Polygon Testnet';
            explorer = 'https://explorer.apothem.network'

            SoulBondTokenAbi = SoulBoundTokenDataMATIC['abi'];
            SoulBondTokenBytecode = SoulBoundTokenDataMATIC['bytecode'];

            CIFIGIVEWhitelistAddress = CIFIGIVEWhitelistDataMATIC['address']
            CIFIGIVEWhitelistABI = CIFIGIVEWhitelistDataMATIC['abi'];
            CIFIGIVEWhitelist = new web3.eth.Contract(CIFIGIVEWhitelistABI, CIFIGIVEWhitelistAddress);
            GreenCrossWhitelistAddress = GreenCrossWhitelistDataMATIC['address']
            GreenCrossWhitelistABI = GreenCrossWhitelistDataMATIC['abi'];
            GreenCrossWhitelist = new web3.eth.Contract(GreenCrossWhitelistABI, GreenCrossWhitelistAddress);

            AdminWhitelistAddress = AdminWhitelistDataMATIC['address']
            AdminWhitelistABI = AdminWhitelistDataMATIC['abi'];
            AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

            MetaDataURIAddress = MetaDataURIContractMATIC['address']
            MetaDataURIABI = MetaDataURIContractMATIC['abi'];
            MetaDataURI = new web3.eth.Contract(MetaDataURIABI, MetaDataURIAddress);

            SubscriptionAddress = SubscriptionDataMATIC['address']
            SubscriptionABI = SubscriptionDataMATIC['abi'];
            Subscription = new web3.eth.Contract(SubscriptionABI, SubscriptionAddress);

            SPVNFTAddress = SPVDataMATIC['address']
            SPVNFTABI = SPVDataMATIC['abi'];
            SPVNFT = new web3.eth.Contract(SPVNFTABI, SPVNFTAddress);

            VIPNFTAddress = VIPNFTDataMATIC['address']
            VIPNFTABI = VIPNFTDataMATIC['abi'];
            VIPNFT = new web3.eth.Contract(VIPNFTABI, VIPNFTAddress);

            GOVNFTAddress = GOVNFTDataMATIC['address']
            GOVNFTABI = GOVNFTDataMATIC['abi'];
            GOVNFT = new web3.eth.Contract(GOVNFTABI, GOVNFTAddress);

            VIPNFTStakeAddress = CIFINFTStakeDataMATIC['vipAddress']
            NFTStakeABI = CIFINFTStakeDataMATIC['abi'];
            VIPNFTStake = new web3.eth.Contract(NFTStakeABI, VIPNFTStakeAddress);

            GOVNFTStakeAddress = CIFINFTStakeDataMATIC['govAddress']
            NFTStakeABI = CIFINFTStakeDataMATIC['abi'];
            GOVNFTStake = new web3.eth.Contract(NFTStakeABI, GOVNFTStakeAddress);

            SP1NFTStakeAddress = SP1DataMATIC['address']
            SP1NFTStakeABI = SP1DataMATIC['abi'];
            SP1NFTStake = new web3.eth.Contract(SP1NFTStakeABI, SP1NFTStakeAddress);

            SP2NFTStakeAddress = SP2DataMATIC['address']
            SP2NFTStakeABI = SP2DataMATIC['abi'];
            SP2NFTStake = new web3.eth.Contract(SP2NFTStakeABI, SP2NFTStakeAddress);

            CIFITokenAddress = TokenData['cifiAddress']
            CIFITokenABI = TokenData['abi'];
            CIFIToken = new web3.eth.Contract(CIFITokenABI, CIFITokenAddress);

            REFITokenAddress = TokenDataMATIC['refiAddress']
            REFITokenABI = TokenDataMATIC['abi'];
            REFIToken = new web3.eth.Contract(REFITokenABI, REFITokenAddress);

            DAOMultiSignatureABI = DAOMultiSignatureData['abi'];
          } else {
            networkId = 51;
            SocketUrl = development;
            message = 'Please switch your network to Polygon Testnet';
            explorer = 'https://explorer.apothem.network'

            SoulBondTokenAbi = SoulBoundTokenData['abi'];
            SoulBondTokenBytecode = SoulBoundTokenData['bytecode'];

            CIFIGIVEWhitelistAddress = CIFIGIVEWhitelistData['address']
            CIFIGIVEWhitelistABI = CIFIGIVEWhitelistData['abi'];
            CIFIGIVEWhitelist = new web3.eth.Contract(CIFIGIVEWhitelistABI, CIFIGIVEWhitelistAddress);
            GreenCrossWhitelistAddress = GreenCrossWhitelistData['address']
            GreenCrossWhitelistABI = GreenCrossWhitelistData['abi'];
            GreenCrossWhitelist = new web3.eth.Contract(GreenCrossWhitelistABI, GreenCrossWhitelistAddress);

            AdminWhitelistAddress = AdminWhitelistData['address']
            AdminWhitelistABI = AdminWhitelistData['abi'];
            AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

            MetaDataURIAddress = MetaDataURIContract['address']
            MetaDataURIABI = MetaDataURIContract['abi'];
            MetaDataURI = new web3.eth.Contract(MetaDataURIABI, MetaDataURIAddress);

            SubscriptionAddress = SubscriptionData['address']
            SubscriptionABI = SubscriptionData['abi'];
            Subscription = new web3.eth.Contract(SubscriptionABI, SubscriptionAddress);

            SPVNFTAddress = SPVData['address']
            SPVNFTABI = SPVData['abi'];
            SPVNFT = new web3.eth.Contract(SPVNFTABI, SPVNFTAddress);

            VIPNFTAddress = VIPNFTData['address']
            VIPNFTABI = VIPNFTData['abi'];
            VIPNFT = new web3.eth.Contract(VIPNFTABI, VIPNFTAddress);

            GOVNFTAddress = GOVNFTData['address']
            GOVNFTABI = GOVNFTData['abi'];
            GOVNFT = new web3.eth.Contract(GOVNFTABI, GOVNFTAddress);

            VIPNFTStakeAddress = CIFINFTStakeData['vipAddress']
            NFTStakeABI = CIFINFTStakeData['abi'];
            VIPNFTStake = new web3.eth.Contract(NFTStakeABI, VIPNFTStakeAddress);

            GOVNFTStakeAddress = CIFINFTStakeData['govAddress']
            NFTStakeABI = CIFINFTStakeData['abi'];
            GOVNFTStake = new web3.eth.Contract(NFTStakeABI, GOVNFTStakeAddress);

            SP1NFTStakeAddress = SP1Data['address']
            SP1NFTStakeABI = SP1Data['abi'];
            SP1NFTStake = new web3.eth.Contract(SP1NFTStakeABI, SP1NFTStakeAddress);

            SP2NFTStakeAddress = SP2Data['address']
            SP2NFTStakeABI = SP2Data['abi'];
            SP2NFTStake = new web3.eth.Contract(SP2NFTStakeABI, SP2NFTStakeAddress);

            CIFITokenAddress = TokenData['cifiAddress']
            CIFITokenABI = TokenData['abi'];
            CIFIToken = new web3.eth.Contract(CIFITokenABI, CIFITokenAddress);

            REFITokenAddress = TokenData['refiAddress']
            REFITokenABI = TokenData['abi'];
            REFIToken = new web3.eth.Contract(REFITokenABI, REFITokenAddress);

            DAOMultiSignatureABI = DAOMultiSignatureData['abi'];
          }

          return resolve({
            networkId, networkIdPolygon, message, explorer, SubscriptionAddress, SubscriptionABI, Subscription, AdminWhitelistAddress, AdminWhitelistABI, AdminWhitelist, SPVNFTAddress, SPVNFTABI, SPVNFT, SoulBondTokenAbi, SoulBondTokenBytecode,
            MetaDataURIAddress, MetaDataURIABI, MetaDataURI, CIFIGIVEWhitelistAddress, CIFIGIVEWhitelistABI, CIFIGIVEWhitelist, GreenCrossWhitelistAddress, GreenCrossWhitelistABI, GreenCrossWhitelist,
            VIPNFTAddress, VIPNFTABI, VIPNFT, GOVNFTAddress, GOVNFTABI, GOVNFT, VIPNFTStakeAddress, GOVNFTStakeAddress, NFTStakeABI, VIPNFTStake, GOVNFTStake,
            CIFITokenAddress, CIFITokenABI, CIFIToken, REFITokenAddress, REFITokenABI, REFIToken, DAOMultiSignatureABI, SP1NFTStakeAddress, SP1NFTStakeABI, SP1NFTStake, SP2NFTStakeAddress, SP2NFTStakeABI, SP2NFTStake
          });
        })
      } else if (AppMode[0] === 'production') {
        return web3.eth.net.getId(async (err, netId) => {
          let networkId = '', message = '', explorer = '', networkIdPolygon = '',
            SoulBondTokenAbi = '', SoulBondTokenBytecode = '', CIFIGIVEWhitelistAddress = '',
            CIFIGIVEWhitelistABI = '', CIFIGIVEWhitelist = '', GreenCrossWhitelistAddress = '', GreenCrossWhitelistABI = '', GreenCrossWhitelist = '',
            AdminWhitelistAddress = '', AdminWhitelistABI = '', AdminWhitelist = '', MetaDataURIAddress = '', MetaDataURIABI = '', MetaDataURI = '',
            SubscriptionAddress = '', SubscriptionABI = '', Subscription = '', SPVNFTAddress = '', SPVNFTABI = '', SPVNFT = '';
          if (netId == 50) {
            networkId = 50;
            SocketUrl = development;
            message = 'Please switch your network to XDC';
            explorer = 'https://xdc.blocksscan.io'

            SoulBondTokenAbi = SoulBoundTokenData['abi'];
            SoulBondTokenBytecode = SoulBoundTokenData['bytecode'];

            CIFIGIVEWhitelistAddress = CIFIGIVEWhitelistData['address']
            CIFIGIVEWhitelistABI = CIFIGIVEWhitelistData['abi'];
            CIFIGIVEWhitelist = new web3.eth.Contract(CIFIGIVEWhitelistABI, CIFIGIVEWhitelistAddress);
            GreenCrossWhitelistAddress = GreenCrossWhitelistData['address']
            GreenCrossWhitelistABI = GreenCrossWhitelistData['abi'];
            GreenCrossWhitelist = new web3.eth.Contract(GreenCrossWhitelistABI, GreenCrossWhitelistAddress);

            AdminWhitelistAddress = AdminWhitelistData['address']
            AdminWhitelistABI = AdminWhitelistData['abi'];
            AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

            MetaDataURIAddress = MetaDataURIContract['address']
            MetaDataURIABI = MetaDataURIContract['abi'];
            MetaDataURI = new web3.eth.Contract(MetaDataURIABI, MetaDataURIAddress);

            SubscriptionAddress = SubscriptionData['address']
            SubscriptionABI = SubscriptionData['abi'];
            Subscription = new web3.eth.Contract(SubscriptionABI, SubscriptionAddress);

            SPVNFTAddress = SPVData['address']
            SPVNFTABI = SPVData['abi'];
            SPVNFT = new web3.eth.Contract(SPVNFTABI, SPVNFTAddress);
          }
          else if (netId == 137) {
            networkId = 137;
            SocketUrl = development;
            message = 'Please switch your network to Polygon';
            explorer = 'https://polygonscan.com'

            SoulBondTokenAbi = SoulBoundTokenData['abi'];
            SoulBondTokenBytecode = SoulBoundTokenData['bytecode'];

            CIFIGIVEWhitelistAddress = CIFIGIVEWhitelistDataMATIC['address']
            CIFIGIVEWhitelistABI = CIFIGIVEWhitelistDataMATIC['abi'];
            CIFIGIVEWhitelist = new web3.eth.Contract(CIFIGIVEWhitelistABI, CIFIGIVEWhitelistAddress);
            GreenCrossWhitelistAddress = GreenCrossWhitelistDataMATIC['address']
            GreenCrossWhitelistABI = GreenCrossWhitelistDataMATIC['abi'];
            GreenCrossWhitelist = new web3.eth.Contract(GreenCrossWhitelistABI, GreenCrossWhitelistAddress);

            AdminWhitelistAddress = AdminWhitelistDataMATIC['address']
            AdminWhitelistABI = AdminWhitelistDataMATIC['abi'];
            AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

            MetaDataURIAddress = MetaDataURIContractMATIC['address']
            MetaDataURIABI = MetaDataURIContractMATIC['abi'];
            MetaDataURI = new web3.eth.Contract(MetaDataURIABI, MetaDataURIAddress);

            SubscriptionAddress = SubscriptionDataMATIC['address']
            SubscriptionABI = SubscriptionDataMATIC['abi'];
            Subscription = new web3.eth.Contract(SubscriptionABI, SubscriptionAddress);

            SPVNFTAddress = SPVDataMATIC['address']
            SPVNFTABI = SPVDataMATIC['abi'];
            SPVNFT = new web3.eth.Contract(SPVNFTABI, SPVNFTAddress);
          } else {
            networkId = 50;
            SocketUrl = development;
            message = 'Please switch your network to XDC';
            explorer = 'https://xdc.blocksscan.io'

            SoulBondTokenAbi = SoulBoundTokenData['abi'];
            SoulBondTokenBytecode = SoulBoundTokenData['bytecode'];

            CIFIGIVEWhitelistAddress = CIFIGIVEWhitelistData['address']
            CIFIGIVEWhitelistABI = CIFIGIVEWhitelistData['abi'];
            CIFIGIVEWhitelist = new web3.eth.Contract(CIFIGIVEWhitelistABI, CIFIGIVEWhitelistAddress);
            GreenCrossWhitelistAddress = GreenCrossWhitelistData['address']
            GreenCrossWhitelistABI = GreenCrossWhitelistData['abi'];
            GreenCrossWhitelist = new web3.eth.Contract(GreenCrossWhitelistABI, GreenCrossWhitelistAddress);

            AdminWhitelistAddress = AdminWhitelistData['address']
            AdminWhitelistABI = AdminWhitelistData['abi'];
            AdminWhitelist = new web3.eth.Contract(AdminWhitelistABI, AdminWhitelistAddress);

            MetaDataURIAddress = MetaDataURIContract['address']
            MetaDataURIABI = MetaDataURIContract['abi'];
            MetaDataURI = new web3.eth.Contract(MetaDataURIABI, MetaDataURIAddress);

            SubscriptionAddress = SubscriptionData['address']
            SubscriptionABI = SubscriptionData['abi'];
            Subscription = new web3.eth.Contract(SubscriptionABI, SubscriptionAddress);

            SPVNFTAddress = SPVData['address']
            SPVNFTABI = SPVData['abi'];
            SPVNFT = new web3.eth.Contract(SPVNFTABI, SPVNFTAddress);
          }
          return resolve({
            networkId, networkIdPolygon, message, explorer, SubscriptionAddress, SubscriptionABI, Subscription, AdminWhitelistAddress, AdminWhitelistABI, AdminWhitelist, SPVNFTAddress, SPVNFTABI, SPVNFT, SoulBondTokenAbi, SoulBondTokenBytecode,
            MetaDataURIAddress, MetaDataURIABI, MetaDataURI, CIFIGIVEWhitelistAddress, CIFIGIVEWhitelistABI, CIFIGIVEWhitelist, GreenCrossWhitelistAddress, GreenCrossWhitelistABI, GreenCrossWhitelist
          });
        })
      }
    }
  });

}

let ApiUrl = `${AppMode[0] === 'development' ? development : production}/api`;
export {
  AppTitle, ApiUrl, SocketUrl, env,
  setProviderAddress
};