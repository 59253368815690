import React from "react";
import { connect } from 'react-redux';
import { PropTypes } from "prop-types";

import './Sidebar.css';
import { logout } from '../../store/actions/Auth';
import { Link } from "react-router-dom";
import { setProviderAddress } from "../../store/config.js"
import { web3 } from "../../store/web3.js";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = {
      networkId:0
    }
  }

  async componentDidMount(){
    const netId = await web3.eth.net.getId();
    this.setState({networkId:netId})
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };

  logout = () => {
    this.props.logout();
    this.props.history.push('/');
  };

  render() {
    const { role, bgColor, routes, rtlActive, logo } = this.props;
    let {networkId} = this.state;

    return (
      <div className="sidebar-kyc" data={bgColor}>
        <div className="logo"><img src="images/logo.svg" alt="logo" />Circularity Finance</div>
        <ul className="nav nav-tabs" id="myTab" role="tablist">

          {/* <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link active" : "nav-link "}`} id="customer-tab" data-toggle="tab" data-target="#customer" type="button" role="tab" aria-controls="customer" aria-selected="true">
              <img src="images/kyc-user.png" alt="" /> KYC Approved
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="customerCreted-tab" data-toggle="tab" data-target="#customerCreted" type="button" role="tab" aria-controls="customer" aria-selected="true">
              <img src="images/kyb-user.png" alt="" /> KYC Created
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="customerApproved-tab" data-toggle="tab" data-target="#customerApproved" type="button" role="tab" aria-controls="customer" aria-selected="true">
              <img src="images/kyb-user.png" alt="" /> KYC Approved by Admin
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="business-tab" data-toggle="tab" data-target="#business" type="button" role="tab" aria-controls="business" aria-selected="true">
              <img src="images/kyb-user.png" alt="" /> KYB & KYN Created
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="businessApproved-tab" data-toggle="tab" data-target="#businessApproved" type="button" role="tab" aria-controls="business" aria-selected="true">
              <img src="images/kyb-user.png" alt="" /> KYB & KYN Approved by Admin
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="whitelistAddress-tab" data-toggle="tab" data-target="#whitelistAddress" type="button" role="tab" aria-controls="whitelistAddress" aria-selected="true">
              <img src="images/kyb-user.png" alt="" /> Whitelist User
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="blacklistAddress-tab" data-toggle="tab" data-target="#blacklistAddress" type="button" role="tab" aria-controls="blacklistAddress" aria-selected="true">
              <img src="images/kyb-user.png" alt="" /> Blacklist User
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/ApproveCifiGive' ? "nav-link" : "nav-link "}`} id="approve-cifigive-tab" data-toggle="tab" data-target="#approve-cifigive" type="button" role="tab" aria-controls="approve-cifigive" aria-selected="true">
              <img src="images/kyc-user.png" alt="" /> Approve Cifi Give
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/BlacklistCifiGive' ? "nav-link" : "nav-link "}`} id="blacklistAddress-cifigive-tab" data-toggle="tab" data-target="#blacklistAddress-cifigive" type="button" role="tab" aria-controls="blacklistAddress-cifigive" aria-selected="true">
              <img src="images/kyb-user.png" alt="" /> Blacklist Cifi Give
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/ApproveGreenCross' ? "nav-link" : "nav-link "}`} id="approve-greencross-tab" data-toggle="tab" data-target="#approve-greencross" type="button" role="tab" aria-controls="approve-greencross" aria-selected="true">
              <img src="images/kyc-user.png" alt="" /> Approve Green Cross
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/BlacklistGreenCross' ? "nav-link" : "nav-link "}`} id="blacklistAddress-greencross-tab" data-toggle="tab" data-target="#blacklistAddress-greencross" type="button" role="tab" aria-controls="blacklistAddress-greencross" aria-selected="true">
              <img src="images/kyb-user.png" alt="" /> Blacklist Green Cross
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="subscription-tab" data-toggle="tab" data-target="#subscription" type="button" role="tab" aria-controls="subscription" aria-selected="true">
              <img src="images/subscribe.png" alt="" /> Subscription
            </button>
          </li>


          <li className="nav-item" role="presentation">
              <button onClick={()=>this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="base-url-price-tab" data-toggle="tab" data-target="#base-url-price" type="button" role="tab" aria-controls="base-url-price" aria-selected="true">
                <img src="images/subscribe.png" alt="" /> Base URI Tool
              </button>
          </li> */}

          <li className="nav-item subtabs-head" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="customer-tab" data-toggle="tab" data-target="#customer" type="button" role="tab" aria-controls="customer" aria-selected="true">
              <img src="images/kyc-user.png" alt="" /> KYC & KYB/KYN
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="customer-tab" data-toggle="tab" data-target="#customer" type="button" role="tab" aria-controls="customer" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              KYC Approved
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="customerCreted-tab" data-toggle="tab" data-target="#customerCreted" type="button" role="tab" aria-controls="customerCreted" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              KYC Created
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="customerApproved-tab" data-toggle="tab" data-target="#customerApproved" type="button" role="tab" aria-controls="customerApproved" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              KYC Approved by Admin
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="business-tab" data-toggle="tab" data-target="#business" type="button" role="tab" aria-controls="business" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              KYB & KYN Created
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="businessApproved-tab" data-toggle="tab" data-target="#businessApproved" type="button" role="tab" aria-controls="businessApproved" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              KYB & KYN Approved by Admin
            </button>
          </li>

          <li className="nav-item subtabs-head" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="whitelistAddress-tab" data-toggle="tab" data-target="#whitelistAddress" type="button" role="tab" aria-controls="whitelistAddress" aria-selected="true">
              <img src="images/subscribe.png" alt="" /> CIFI
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="whitelistAddress-tab" data-toggle="tab" data-target="#whitelistAddress" type="button" role="tab" aria-controls="whitelistAddress" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              Approve CIFI
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="blacklistAddress-tab" data-toggle="tab" data-target="#blacklistAddress" type="button" role="tab" aria-controls="blacklistAddress" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              Blacklist CIFI
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="subscription-tab" data-toggle="tab" data-target="#subscription" type="button" role="tab" aria-controls="subscription" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              CIFI Subscription
            </button>
          </li>
          {networkId && networkId == 51 &&
          <>
          <li className="nav-item subtabs-head" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="approve-cifigive-tab" data-toggle="tab" data-target="#approve-cifigive" type="button" role="tab" aria-controls="approve-cifigive" aria-selected="true">
              <img src="images/kyb-user.png" alt="" /> CIFI Give
            </button>
          </li>

          

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="approve-cifigive-tab" data-toggle="tab" data-target="#approve-cifigive" type="button" role="tab" aria-controls="approve-cifigive" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              Approve CIFI Give
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="blacklistAddress-cifigive-tab" data-toggle="tab" data-target="#blacklistAddress-cifigive" type="button" role="tab" aria-controls="blacklistAddress-cifigive" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              Blacklist CIFI Give
            </button>
          </li>


          {/* <li className="nav-item subtabs-head" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="approve-greencross-tab" data-toggle="tab" data-target="#approve-greencross" type="button" role="tab" aria-controls="approve-greencross" aria-selected="true">
              <img src="images/kyb-user.png" alt="" /> Green Cross
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="approve-greencross-tab" data-toggle="tab" data-target="#approve-greencross" type="button" role="tab" aria-controls="approve-greencross" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              Approve Green Cross
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="blacklistAddress-greencross-tab" data-toggle="tab" data-target="#blacklistAddress-greencross" type="button" role="tab" aria-controls="blacklistAddress-greencross" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              Blacklist Green Cross
            </button>
          </li> */}
          </>
          }
          
          <li className="nav-item subtabs-head" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="stats-tab" data-toggle="tab" data-target="#stats" type="button" role="tab" aria-controls="stats" aria-selected="true">
              <img src="images/chart-square.png" alt="" /> Stats
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="stats-tab" data-toggle="tab" data-target="#stats" type="button" role="tab" aria-controls="stats" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              Governor NFT/VIP NFT
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="dao-tab" data-toggle="tab" data-target="#dao" type="button" role="tab" aria-controls="dao" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              DAO
            </button>
          </li>

          <li className="nav-item subtab" role="presentation">
            <button onClick={() => this.props.history.push("/home")} className={`${window.location.hash !== '#/home/KYCApproved' ? "nav-link" : "nav-link "}`} id="stability-pool-tab" data-toggle="tab" data-target="#stability-pool" type="button" role="tab" aria-controls="stability-pool" aria-selected="true">
              <img src="images/subtab-icon-new.png" alt="" />
              <img className="active-icon" src="images/subtab-fill-icon.svg" alt="" />
              Stability pool
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button className="nav-link" onClick={this.logout}><img src="images/logout.svg" alt="" /> Logout</button>
          </li>
        </ul>

      </div>
    );
  }
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: "primary",
  routes: [{}]
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(["primary", "blue", "green"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string
  })
};

// export default Sidebar;


const mapDispatchToProps = { logout };

const mapStateToProps = ({ Auth }) => {
  let { role } = Auth;
  return { role };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);